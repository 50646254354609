import { useQuery } from '@tanstack/vue-query'
import { useApi } from '@tenant/composables'
import { computed } from 'vue'
export function useUnits() {
    const { execute } = useApi('/api/units', 'GET')
    const units = computed(() => data.value ?? [])

    const { data } = useQuery({
        queryKey: ['units'],
        queryFn: () =>
            execute({
                queries: {
                    all: true,
                },
            }),
        staleTime: Infinity,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
    })

    return { units, getUnits: execute }
}
